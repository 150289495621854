import React, { Component } from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { callApi } from '../includes/Helper';
import { ColumnChart } from 'react-chartkick';
// import Lottie from 'react-lottie';
import auth from '../Auth';
// import animationData from '../assets/animations/44190-under-construction-1.json';
import 'chartkick/chart.js';

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

class Metrics extends Component {
  state = {
    token: '',
    selectedDateRange: { value: 'TODAY', label: 'TODAY' },
    groupedScans: [],
    activeMembers: [],
    selectedTeamMember: {
      label: 'All Scouts',
      value: -1,
      data: {
        cy: 'option-default',
      },
    },
    overview: {
      total_accepts: 0,
      total_buy_cost: 0,
      total_estimated_profit: 0,
      total_list_price: 0,
      total_rejects: 0,
      total_scans: 0,
    },
    isLoadingTeamBreakDown: true,
    isLoadingOverview: true,
    isLoadingChart: true,
    isLoadingLifetimeScans: true,
    isLoadingBestDay: true,
    lifetimeScans: [],
    backdatedScansCount: 0,
    lifetimeScansCount: 0,
    lifetimeAcceptCount: 0,
    bestDay: {},
    chartData: [],
    userData: auth.getAdminData(),
  };

  constructor() {
    super();
    this.loadTeamScans = this.loadTeamScans.bind(this);
    this.loadLifetime = this.loadLifetime.bind(this);
  }

  componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    const urlToken = params.get('token');

    this.setState({ token: urlToken }, () => {
      this.loadTeamScans();
      this.loadLifetime();
    });
  }

  getFormattedNumber = (x) => {
    return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0';
  };

  loadLifetime() {
    this.setState({ isLoadingLifetimeScans: true, isLoadingBestDay: true });
    callApi(
      'stats/lifetime-scans',
      'POST',
      {
        token: this.state.token,
      },
      (resp) => {
        if (resp.status) {
          const scans = resp.data;
          let lifetimeScans = scans.reduce((sum, x) => sum + x.scans, 0);
          let lifetimeAccepts = scans
            .filter((scan) => scan.accept_reject === 'ACCEPT')
            .reduce((sum, x) => sum + x.scans, 0);

          if (resp.backdated_estimate && !isNaN(resp.backdated_estimate) && resp.backdated_estimate > 0) {
            lifetimeScans += resp.backdated_estimate;
            lifetimeAccepts += (lifetimeAccepts / lifetimeScans) * resp.backdated_estimate;
          }

          this.setState({
            lifetimeScans: resp.data,
            lifetimeScansCount: lifetimeScans,
            lifetimeAcceptCount: parseInt(lifetimeAccepts),
          });
        }
        this.setState({ isLoadingLifetimeScans: false });
      }
    );

    callApi(
      'stats/best-day',
      'POST',
      {
        token: this.state.token,
      },
      (resp) => {
        if (resp.status) this.setState({ bestDay: resp.data });
        this.setState({ isLoadingBestDay: false });
      }
    );
  }

  loadTeamScans() {
    this.setState({ isLoadingOverview: true, isLoadingTeamBreakDown: true, isLoadingChart: true });
    callApi(
      'stats/team_breakdown',
      'POST',
      {
        group_by: this.state.selectedDateRange.value,
        user_id: this.state.selectedTeamMember.value || -1,
        token: this.state.token,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      (resp) => {
        if (resp.status) {
          const scouts = [
            {
              label: 'All Scouts',
              value: -1,
              data: {
                cy: 'option-default',
              },
            },
          ].concat(
            resp.data.map((member) => {
              return {
                label: member.scout_name,
                value: member.id,
                classNames: ['other-option-dd'],
              };
            })
          );
          this.setState({
            groupedScans: resp.data,
            activeMembers: scouts,
          });
        }
        this.setState({ isLoadingTeamBreakDown: false });
      }
    );

    callApi(
      'stats/overview',
      'POST',
      {
        group_by: this.state.selectedDateRange.value,
        user_id: this.state.selectedTeamMember.value || -1,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        token: this.state.token,
      },
      (resp) => {
        if (resp.status) this.setState({ overview: resp.data });
        this.setState({ isLoadingOverview: false });
      }
    );

    callApi(
      'stats/chart',
      'POST',
      {
        group_by: this.state.selectedDateRange.value,
        user_id: this.state.selectedTeamMember.value || -1,
        token: this.state.token,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      (resp) => {
        if (resp.status) this.setState({ chartData: resp.data });
        this.setState({ isLoadingChart: false });
      }
    );
  }

  render() {
    // const defaultLottieOptions = {
    //   loop: true,
    //   autoplay: true,
    //   animationData: animationData,
    //   rendererSettings: {
    //     preserveAspectRatio: 'xMidYMid slice',
    //   },
    // };

    const ddOptionsDateRange = [
      'TODAY',
      'YESTERDAY',
      'THIS WEEK',
      'LAST WEEK',
      'THIS MONTH',
      'LAST MONTH',
      'THIS YEAR',
      'LAST YEAR',
    ].map((range) => {
      return { label: range, value: range };
    });

    return (
      <React.Fragment>
        {/* <TopMenu adminData={this.state.admin_data} history={this.props.history} activeKey="/" /> */}
        <Container className="page-dashboard">
          <div style={{ marginTop: '20px', paddingBottom: '20px' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly' }}>
              <div className="stats-overview high-level-overview">
                <div className="icon">
                  <img alt="Total Scans" src={require('../assets/images/qr-code-scan.svg')} />
                </div>
                <div className="title">Lifetime Scans</div>
                <div className="count">
                  {!this.state.isLoadingLifetimeScans && this.state.lifetimeScansCount
                    ? this.getFormattedNumber(this.state.lifetimeScansCount)
                    : '...'}

                  <div className="overview-legend">
                    {!this.state.isLoadingLifetimeScans &&
                      ((this.state.lifetimeAcceptCount * 100) / this.state.lifetimeScansCount).toFixed(0) +
                        '% Accept Rate'}
                  </div>
                </div>
              </div>

              <div className="stats-overview high-level-overview">
                <div className="icon">
                  <img alt="Total Scans" src={require('../assets/images/trophy-svgrepo-com.svg')} />
                </div>
                <div className="title">Best Day</div>
                <div className="count">{this.state.isLoadingBestDay ? '...' : this.state.bestDay.date}</div>
                <div className="overview-legend">
                  {!this.state.isLoadingBestDay &&
                    this.state.bestDay.scan &&
                    this.getFormattedNumber(this.state.bestDay.scan) + ' scans'}
                </div>
              </div>
            </div>

            <Row style={{ marginTop: '20px' }}>
              <Col md={6} sm={6} xs={6}>
                <Dropdown
                  options={ddOptionsDateRange}
                  onChange={(col) => {
                    this.setState({ selectedDateRange: col }, () => {
                      this.loadTeamScans();
                    });
                  }}
                  placeholder="Select Period"
                  value={this.state.selectedDateRange}
                />
              </Col>
              <Col md={6} sm={6} xs={6}>
                {this.state.selectedTeamMember.value && (
                  <Dropdown
                    placeholder="Select Scout"
                    options={this.state.activeMembers}
                    onChange={(col) => {
                      console.log(`Selected Team Member: `, col);
                      this.setState({ selectedTeamMember: col }, () => {
                        this.loadTeamScans();
                      });
                    }}
                    value={this.state.selectedTeamMember}
                  />
                )}
              </Col>
            </Row>

            <div
              style={{
                display: 'flex',
                marginTop: '20px',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
              }}
            >
              <div className="stats-overview">
                <div className="icon">
                  <img alt="Total Scans" src={require('../assets/images/qr-code-scan.svg')} />
                </div>
                <div className="title">Total Scans</div>
                <div className="count">
                  {this.state.isLoadingOverview ? '...' : this.getFormattedNumber(this.state.overview.total_scans)}
                </div>
              </div>
              <div className="stats-overview">
                <div className="icon">
                  <img alt="Total Scans" src={require('../assets/images/accept.svg')} />
                </div>
                <div className="title">Total Accepts</div>
                <div className="count">
                  {this.state.isLoadingOverview ? '...' : this.getFormattedNumber(this.state.overview.total_accepts)}
                </div>
              </div>
              <div className="stats-overview">
                <div className="icon">
                  <img alt="Total Scans" src={require('../assets/images/ratio.svg')} />
                </div>
                <div className="title">Accept Rate</div>

                <div className="count">
                  {this.state.isLoadingOverview
                    ? '...'
                    : parseInt(this.state.overview.total_scans) > 0
                    ? this.getFormattedNumber(
                        ((this.state.overview.total_accepts * 100) / this.state.overview.total_scans).toFixed(0)
                      ) + '%'
                    : '0%'}
                </div>
              </div>
              <div className="stats-overview">
                <div className="icon">
                  <img alt="Total Scans" src={require('../assets/images/surface1-money.svg')} />
                </div>
                <div className="title">Total List Price</div>
                <div className="count">
                  {this.state.isLoadingOverview
                    ? '...'
                    : this.state.overview.total_list_price > 0
                    ? '$' + this.getFormattedNumber(parseInt(this.state.overview.total_list_price))
                    : '$0'}
                </div>
              </div>
              <div className="stats-overview">
                <div className="icon">
                  <img alt="Total Scans" src={require('../assets/images/save-money.svg')} />
                </div>
                <div className="title">Total Buy Cost</div>
                <div className="count">
                  {this.state.isLoadingOverview
                    ? '...'
                    : this.state.overview.total_buy_cost > 0
                    ? '$' + this.getFormattedNumber(parseInt(this.state.overview.total_buy_cost).toFixed(0))
                    : '$0'}
                </div>
              </div>
              <div className="stats-overview">
                <div className="icon">
                  <img alt="Total Scans" src={require('../assets/images/budget.svg')} />
                </div>
                <div className="title">Estimated Profit</div>
                <div className="count">
                  {this.state.isLoadingOverview
                    ? '...'
                    : this.state.overview.total_estimated_profit > 0
                    ? '$' + this.getFormattedNumber(parseInt(this.state.overview.total_estimated_profit).toFixed(0))
                    : '$0'}
                </div>
              </div>
            </div>

            <div style={{ marginTop: '80px' }}>
              <ColumnChart data={this.state.chartData} />
            </div>

            <div style={{ marginTop: '60px' }}>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Scout Name</th>
                    <th>Total Scans</th>
                    <th>Total Accepts</th>
                    <th>Accept Rate</th>
                    <th>Total List</th>
                    <th>Total Buy Cost</th>
                    <th>Est. Profit</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.groupedScans.map((member, index) => (
                    <tr key={`scout_count_${index}`}>
                      <td>{member.scout_name}</td>
                      <td>{member.total_scans}</td>
                      <td>{member.accepts}</td>
                      <td>{member.accept_rate}</td>
                      <td>{member.total_list_price}</td>
                      <td>{member.total_buy_cost}</td>
                      <td>{member.total_estimated_profit}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}
export default Metrics;
