import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import auth from './Auth';
import { Spinner } from 'react-bootstrap';

class ProtectedRoute extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isLoading: true,
      isLoggedIn: false,
      urlToken: '',
    };

    let urlToken = '';
    let search = window.location.search;
    let params = new URLSearchParams(search);
    urlToken = params.get('token');

    // }

    this.validateToken(urlToken);
  }

  async validateToken(token) {
    console.log(`Validating ${token}`);
    const isAuthenticated = await auth.isAuthenticated(token);
    this.setState(() => ({ isLoading: false, urlToken: token, isLoggedIn: isAuthenticated }));
  }

  render() {
    return this.state.isLoading ? (
      <div style={{ textAlign: 'center', fontSize: '34px', marginTop: '50px' }}>
        <Spinner
          animation="border"
          role="status"
          style={{ marginRight: '10px', width: '40px', height: '40px' }}
        ></Spinner>{' '}
        Loading ...
      </div>
    ) : this.state.isLoggedIn ? (
      <Route
        path={this.props.path}
        urlToken={this.state.urlToken}
        component={this.props.component}
        exact={this.props.exact}
      />
    ) : (
      <Redirect to={{ pathname: '/invalid-token', state: { from: this.props.location } }} />
    );
  }
}

export default ProtectedRoute;
