import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Route404 from './pages/Route404';
import './assets/style/theme_minimal.css';
import ProtectedRoute from './ProtectedRoute';
import Metrics from './pages/_Metrics';
import InvalidToken from './pages/InvalidToken';

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Router>
          <Switch>
            {/* <Route component={Metrics} /> */}
            <ProtectedRoute exact path="/metric" component={Metrics} />
            <Route exact path="/invalid-token" component={InvalidToken} />
            <Route component={Route404} />
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

export default App;
