import React, { Component } from 'react';
import Lottie from 'react-lottie';
import animationData from '../assets/animations/98642-error-404.json';

class Route404 extends Component {
  state = {};
  render() {
    const defaultLottieOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };

    return (
      <div style={{ textAlign: 'center', paddingTop: '50px' }}>
        <Lottie options={defaultLottieOptions} height={400} width={400} />
      </div>
    );
  }
}

export default Route404;
