import Cookies from 'universal-cookie';
import { apiBaseUrlLocal } from './includes/AppConfig';

class Auth {
  constructor() {
    this.cookies = new Cookies();
  }

  getAdminData() {
    return this.cookies.get('iq2_admin_data');
  }

  setAdminData(userData) {
    this.cookies.set('iq2_admin_data', userData, { path: '/' });
  }

  async isAuthenticated(token) {
    try {
      console.log(`Validating Token`);
      const response = await fetch(`${apiBaseUrlLocal}user/validate-token?id=${token}`);
      const responseJson = await response.json();
      if (response.status === 200) {
        this.setAdminData(responseJson.data);
        return true;
      } else {
        console.log(`Didn't match`);
        return false;
      }
    } catch (error) {
      return false;
    }
  }
}

// Exporting singleton class
export default new Auth();
