import { apiBaseUrlLocal } from './AppConfig';

export const callApi = (endpoint, method, options, callback) => {
  fetch(apiBaseUrlLocal + endpoint, {
    method,
    body: new URLSearchParams(options),
  })
    .then((resp) => resp.json())
    .then((data) => {
      callback(data);
    });
};
