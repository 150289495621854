import React, { Component } from 'react';
import iconInvalid from '../assets/images/invalid.png';

class InvalidToken extends Component {
  state = {};
  render() {
    return (
      <div style={{ textAlign: 'center', paddingTop: '50px' }}>
        <img alt="Invalid Token" style={{ width: '150px' }} src={iconInvalid} />
        <div style={{ fontWeight: 'bold', fontSize: '20px', marginTop: '20px' }}>Invalid Token. Request invalid.</div>
      </div>
    );
  }
}

export default InvalidToken;
